import React from "react";

const Form = (props) => {
 
  return (
    <div className="p-5">
      <div class="elfsight-app-273191c0-9f76-4253-8ca7-cf84ff200f46" data-elfsight-app-lazy></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
